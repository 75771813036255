import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from '../styles/helpers';
import { offsets, fontSizes, transitions, colors } from '../styles';
import { Jumbotron } from '../components';

const Title = styled.h1`
  font-size: ${fontSizes.xl};
  margin: 0;
  font-weight: 300;
  text-transform: uppercase;

  ${media.sm`
    font-size: 34px;
  `}

  ${media.md`
    font-size: 42px;
  `}
`;

export default () => (
  <Jumbotron appearance="secondary">
    <Title>Меню</Title>
  </Jumbotron>
);
