import React from 'react';
import { Layout } from '../components';
import Jumbotron from '../containers/jumbotron-order-page';
import Menu from '../containers/menu';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Мама Food" keywords={[`Пицца`, `доставка`, `бургеры`]} />
    <Jumbotron />
    <Menu />
  </Layout>
);

export default IndexPage;
